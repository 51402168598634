.faq-list {
  // width: 55em;
  width: 75vw;
  margin: 1em auto;
  padding: 1em 0;
  text-align: justify;
  div {
    border-bottom: 0.07em solid #ededed;
    padding: 1em 0em;
    &:last-child {
      border: initial;
    }
  }
  summary {
    font-size: 1.2em;
    // font-weight: bold;
    cursor: pointer;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    &:hover {
      color: #00afef;
      transition: all 0.3s ease;
    }
    &::-webkit-details-marker {
      display: none;
    }
    &:after {
      background: transparent;
      border-radius: 0.3em;
      content: "+";
      color: #00afef;
      float: left;
      font-size: 1.8em;
      font-weight: bold;
      margin: -0.3em 0.25em 0 0;
      padding: 0;
      text-align: center;
      width: 25px;
    }
    &:focus {
      outline: none;
    }
  }
  details[open] summary~* {
    animation: sweep .5s ease-in-out;
  }
  @keyframes sweep {
    0% {
      opacity: 0;
      margin-left: -10px
    }
    100% {
      opacity: 1;
      margin-left: 55px
    }
  }
  details[open] summary {
    color: #00afef;
  }
  details[open] p {
    border-left: 2px solid #00afef;
    margin-left: 55px;
    padding-left: 25px;
    opacity: 100;
    transition: all 3s ease;
  }
  details[open] summary:after {
    content: "-";
    font-size: 3.2em;
    margin: -0.5em 0.14em 0 0;
    font-weight: 200;
  }
}